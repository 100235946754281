.App {
	background-color: #0a1f36;
	min-height: calc(100vh - 120px);
	position: relative;
	padding-bottom: 120px;
	.block {
		padding: 20px 25px 25px 25px;
		background-color:rgba(255, 255, 255, 0.9);
		&.blue {
			background-color: #013b52;
			color: rgba(255, 255, 255, 0.9);
		}
		&.centered {
			text-align: center;
		}
		border-radius: 5px;
		color: rgba(0, 0, 0, 0.8);
		width: 820px;
		max-width: 100%;
		box-sizing: border-box;
		margin: 0 auto 30px auto;
		
		.title {
			font-weight: 600;
			font-size: 26px;
			&.large {
				font-size: 42px;
			}
		}
		
		@keyframes grow {
			0% {
				opacity: 0.9;
				transform: scale(0.97);
			}
			50% {
				opacity: 1;
				transform: scale(1.06);
			}
			100% {
				opacity: 0.9;
				transform: scale(0.97);
			}
		}

		@keyframes glow {
			0% {
				border: 2px solid rgba(255, 85, 85, 0.6);
			}
			50% {
				border: 4px solid rgba(255, 85, 85, 0.9);
				padding: 18px 23px 23px 23px;
			}
			100% {
				border: 2px solid rgba(255, 85, 85, 0.6);
			}
		}

		.header {
			.title.clickable:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}

		&.live {
			animation: glow 3s infinite alternate ease;
			
		}
		&.red-border {
			border: 2px solid rgba(255, 85, 85, 0.9);
		}
		.live {
			background-color:rgba(255, 85, 85, 1);
			font-weight: bold;
			display: inline-block;
			padding: 2px 8px;
			border-radius: 3px;
			margin-right: 10px;
			margin-top: 5px;
			animation: grow 3s infinite alternate ease;
		}
		.subtitle {
			margin-top: -20px;
			color: rgb(161, 161, 161);
		}
		.description {
			a:hover {
				text-decoration: underline;
			}
		}
	}
	
	.error {
		width: 404px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	.embed-container {
		position: relative;
		height: calc(100vh - 720px);
	}

	.embed {
		width: 100%;
		box-sizing: border-box;
		margin-top: 40px;
		.title {
			color: #fff;
			opacity: 0.4;
			cursor: default;
			transition: opacity 0.4s ease;
			font-size: 21px;
			padding: 10px 2px;
			font-family: 'Roboto';
			&:hover {
				opacity: 0.9;
			}
		}
	}

	.video {
		width: 100%;
		aspect-ratio: 16 / 9;
		.video-inner {
			width: 100%;
			height: 100%;
		}
	}

	.chat, .history {
		width: calc(100% - 20px);
		height: 100%;
		margin-left: 20px;
	}

	.video-inner, .chat, .history, .block {
		box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.25);
	}

	.history {
		overflow-y: auto;
		background-color: rgba(33, 33, 33, 0.98);
		color: rgba(255, 255, 255, 0.5);

		.history-item {
			padding: 10px 20px;
			transition: background-color 0.1s ease-in-out;
			border: 0.5px solid rgba(0,0,0,0.2);
			cursor: pointer;
			position: relative;
			.download-button {
				color: rgba(255, 255, 255, 0.5);
				padding: 6px;
				position: absolute;
				right: 5px;
				bottom: 5px;
				&:hover {
					background-color: rgba(255, 255, 255, 0.1);
					color: rgba(255, 255, 255, 0.9);
				}
			}
			* {
				cursor: pointer;
			}
			z-index: 10;
			&.active, &:hover {
				color: rgba(255, 255, 255, 0.7);
				background-color: rgb(55,55,55);
				.title {
					opacity: 1;
				}
			}
			&:hover {
				box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
				z-index: 100;
			}
			.title {
				color: rgba(255, 255, 255, 1);
				font-weight: bold;
				font-size: 16px;
			}
		}
	}
	
	.header {
		.logo {
			height: 40px;
			margin: 10px 15px;
			max-width: 100vw;
		}
		.button {
			margin-top: -45px;
			margin-left: 12px;
			font-weight: 500;
			&.active {
				background-color: #43bdce;
			}
			
			&.orange {
				background-color: rgba(255, 85, 85, 0.74);
			}
		}
	}

	.klub-logo {
		height: 100px;
		position: absolute;
		right: 60px;
		bottom: 20px;
		opacity: 0.6;
	}

	.http-logo {
		height: 50px;
		position: absolute;
		right: 210px;
		bottom: 40px;
		opacity: 0.6;
	}

	.netacad-logo {
		height: 40px;
		position: absolute;
		right: 380px;
		bottom: 45px;
		opacity: 0.6;
	}

	
}

@media only screen and (max-width: 960px) {
	.App {
		.header {
			padding: 10px;
			text-align: center;
			.logo {
				width: 100%;
				max-width: 300px;
				height: auto;
				box-sizing: border-box;
				margin: 3px 0 10px 0;
			}
			.button {
				box-sizing: border-box;
				width: 100%;
				margin: 3px 0;
			}
		}
		min-height: calc(69.4vw + 750px);
		padding-bottom: 255px;
		.embed {
			margin-top: 10px;
		}
		.chat, .history {
			margin-left: 0;
			margin-top: 20px;
			width: 100%;
			height: 500px;
		}
		img:not(.logo) {
			left: 50%;
			transform: translateX(-50%);
		}
		.http-logo{
			bottom: 90px;
		}
		.netacad-logo {
			bottom: 20px;
		}
		.klub-logo {
			bottom: 170px;
		}
	}
}

.icon {
	height: 20px;
	margin: 1px -5px 0 5px;
	&.left {
		margin: -2px 9px 0 -3px;
	}
}

.orDivider {
	position: relative;
	padding: 40px 0 30px 0;
	.or {
		position: absolute;
		left: 50%;
		top: 30px;
		font-size: 13px;
		transform: translateX(-50%);
		background-color: #fff;
		padding: 0 8px;
		color: rgba(0, 0, 0, 0.4)
	}
}

a.link {
	color: #006188;
	&.underline {
		text-decoration: underline;
	}
	&:hover {
		text-decoration: underline;
	}
}

button, .button {
	text-transform: none !important;
}