.admin {
	.logo {
		margin: 10px 20px 0 25px;
		height: 40px;
	}
	.col {
		box-sizing: border-box;
		.paper {
			.title {
				position: relative;

				font-weight: 700;
				padding: 10px 20px 20px 20px;
				margin: 0;
				font-size: 20px;

				.circle-button {
					border-radius: 50%;
					min-width: 0px;
					width: 25px;
					height: 25px;
					padding: 19px;
					position: absolute;
					right: 15px;
					top: 5px;
				}
			}
			.list {
				padding: 0;
			}
			padding: 10px 0;
			&.pad {
				padding: 20px;
			}
			.live {
				width: 13px;
				height: 13px;
				background-color: red;
				display: inline-block;
				border-radius: 50%;
				position: relative;
				margin-right: 8px;
				border: 1.5px solid #000;
				transform: translateY(2px);
			}
		}
	}
	.editor {
		min-height: 200px;
		&>div.tox.tox-tinymce {
			border-radius: 4px;
		}
	}
}